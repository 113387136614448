import React from "react";
import Footer from "../../components/sections/Footer";
import Header from "../../components/sections/Header";
import HeadData from "../../data/HeadData";
import ContentContainer from "../../components/container/ContentContainer";
import TitleDiv from "../../components/controls/TitleDiv";
import { getImage } from "gatsby-plugin-image";
import { Link, useStaticQuery, graphql } from "gatsby";

import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const Jobs = ({ jobTitle, image }) => {
  return (
    <Link to="./employment-application">
      <BackgroundImage
        tag="Jobs"
        {...image}
        className="w-full py-32 bg-blend-darken bg-gray3 bg-opacity-50 text-white flex items-center justify-center"
      >
        <div className="min-w-20 bg-actionBlue text-white inline-block px-8 py-3 text-lg rounded-full text-center">
          {jobTitle}
        </div>
      </BackgroundImage>
    </Link>
  );
};

const JobsPage = () => {
  const images = useStaticQuery(graphql`
    {
      warehouseWorkers: file(relativePath: { eq: "warehouse-workers.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
      outsideSales: file(relativePath: { eq: "outside-sales.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
      truckDrivers: file(relativePath: { eq: "truck-drivers.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        }
      }
    }
  `);

  const jobList = [
    {
      title: "Warehouse Workers",
      image: convertToBgImage(getImage(images.warehouseWorkers)),
    },
    {
      title: "Outside Sales",
      image: convertToBgImage(getImage(images.outsideSales)),
    },
    {
      title: "Class A Truck Drivers",
      image: convertToBgImage(getImage(images.truckDrivers)),
    },
  ];

  return (
    <div className="w-full max-w-full relative overflow-hidden">
      <HeadData title="Jobs" />
      <Header />
      <TitleDiv title="Jobs" />
      <ContentContainer className="pt-10 md:pt-24 pb-11 gap-y-10 md:gap-y-24">
        <p className="lg:pr-72 xl:pr-96">
          At <strong>National Wood Products</strong>, we help businesses grow by
          supplying them with quality wood products. We don’t just provide the
          best raw materials, but also deliver great customer service. Join us!
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-11">
          {jobList.map((job, index) => {
            return (
              <Jobs
                key={`job${index}`}
                jobTitle={job.title}
                image={job.image}
              />
            );
          })}
        </div>
      </ContentContainer>
      <Footer />
    </div>
  );
};

export default JobsPage;
